import Papa from 'papaparse'
import moment from 'moment'

const BOM = '\uFEFF'
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const createBrokerCsvExport = (rows: Record<string, any>[]) => {
    const csv = Papa.unparse([
        [
            'id',
            'createdAt',
            'updatedAt',
            'firstName',
            'lastName',
            'email',
            'refererToken',
            'nbReferredUsers',
            'source',
        ],
        ...rows.map(row => [
            row.id,
            moment.utc(row.createdAt).format(DATETIME_FORMAT),
            moment.utc(row.updatedAt).format(DATETIME_FORMAT),
            row.firstName,
            row.lastName,
            filterEmail(row.email),
            row.refererToken,
            row.nbReferredUsers ?? 0,
            row.source,
        ])
    ])

    return BOM + csv
}

const filterEmail = (email: string): string => {
    return /^pro-user-[^@]*@movingwaldo\.ca$/.test(email) ? '' : email
}